import {
  Button as HeadlessButton,
  type ButtonProps as HeadlessButtonProps,
} from "@headlessui/react";
import { IconContext } from "@phosphor-icons/react/dist/lib/context";
import { clsx } from "clsx";
import { forwardRef } from "react";

import { Link } from "../Link";

import type { IconProps } from "@phosphor-icons/react";

// type ButtonProps = (
//   | { color?: keyof typeof styles.colors; outline?: never; plain?: never }
//   | { color?: never; outline: true; plain?: never }
//   | { color?: never; outline?: never; plain: true }
// ) & { children: React.ReactNode } & (HeadlessButtonProps | React.ComponentPropsWithoutRef<typeof Link>)

const styles = {
  base: [
    // base
    "relative isolate inline-flex items-center justify-center gap-x-2 rounded-xl border text-base font-semibold",

    // sizing
    "px-4 py-2",

    // focus
    "focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500",

    // disabled
    "data-[disabled]:opacity-50",

    // transitions
    "transition after:transition before:transition",

    // prevent text wrap
    "whitespace-nowrap",

    // Press down
    "active:hover:scale-95 active:hover:before:opacity-50",
  ],
  modes: {
    solid: [
      // No border
      "border-transparent",

      // Dark mode: border is rendered on `after` so background is set to button background
      "dark:bg-[--btn-bg]",

      // Button background
      "bg-[--btn-bg]",

      // shadow layer
      "before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.xl)-1px)]",

      // shadow is removed in dark mode so hide `before` pseudo
      "dark:before:hidden",

      // Dark mode: Subtle white outline is applied using a border
      "dark:border-white/5",

      // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
      "after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.xl)-1px)]",

      // Inner highlight shadow
      "after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]",

      // White overlay on hover
      "after:data-[active]:bg-[--btn-hover-overlay] after:data-[hover]:bg-[--btn-hover-overlay]",

      // Dark mode: `after` layer expands to cover entire button
      "dark:after:-inset-px dark:after:rounded-xl",

      // Disabled
      "before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none",
    ],
    ghost: [
      // similar to solid but backgrounds/shadows/borders only reveal on hover
      "border-transparent",
      "hover:dark:bg-[--btn-bg]",
      "hover:bg-[--btn-bg]",
      "before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.xl)-1px)]",
      "dark:before:hidden",
      "hover:dark:border-white/5",
      "after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.xl)-1px)]",
      "hover:after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]",
      "after:data-[active]:bg-[--btn-hover-overlay] after:data-[hover]:bg-[--btn-hover-overlay]",
      "dark:after:-inset-px dark:after:rounded-xl",
      "before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none",
      "before:shadow-none",
    ],
  },
  circular: [
    "!rounded-full !aspect-square !p-2",
    "before:!rounded-full after:!rounded-full dark:after:!rounded-full",
  ],
  pill: [
    "!rounded-full",
    "before:!rounded-full after:!rounded-full dark:after:!rounded-full",
  ],
  small: [
    "!rounded-md !text-sm !px-1.5 !py-1",
    "before:!rounded-md after:!rounded-md dark:after:!rounded-md",

    // "hover:before:opacity-50",
  ],
  surfaces: {
    system: [
      "text-fg-primary dark:text-fg-primary-dark",
      "[--btn-bg:theme(colors.system.regular)] [--btn-hover-overlay:theme(colors.white/20%)]",
      "dark:[--btn-bg:theme(colors.system.regular-dark)] dark:[--btn-hover-overlay:theme(colors.white/10%)]",
      "[--icon-color:theme(colors.fg.secondary)] dark:[--icon-color:theme(colors.fg.secondary-dark)]",
      "backdrop-blur-lg",
      "hover:before:shadow-lg",
    ],
    material: [
      "text-fg-primary dark:text-fg-primary-dark",
      "[--btn-bg:theme(colors.material.regular)] [--btn-hover-overlay:theme(colors.white/20%)]",
      "dark:[--btn-bg:theme(colors.material.regular-dark)] dark:[--btn-hover-overlay:theme(colors.white/10%)]",
      "[--icon-color:theme(colors.fg.secondary)] dark:[--icon-color:theme(colors.fg.secondary-dark)]",
      "backdrop-blur-lg",
      "hover:before:shadow-lg",
    ],
    chrome: [
      "text-fg-primary dark:text-fg-primary-dark",
      "[--btn-bg:theme(colors.system.chrome)]",
      "dark:[--btn-bg:theme(colors.system.chrome-dark)] dark:[--btn-hover-overlay:theme(colors.white/5%)]",
      "[--icon-color:theme(colors.fg.primary)] dark:[--icon-color:theme(colors.fg.primary-dark)]",
      "before:shadow hover:before:shadow-lg",
    ],
    solid: [
      "text-fg-primary-dark [--btn-bg:theme(colors.action)] [--btn-hover-overlay:theme(colors.white/15%)]",
      "dark:text-fg-primary-dark dark:[--btn-bg:theme(colors.action)] dark:[--btn-hover-overlay:theme(colors.white/15%)]",
      "[--icon-color:theme(colors.fg.primary-dark)] dark:[--icon-color:theme(colors.fg.primary-dark)]",
      "before:shadow hover:before:shadow-lg",
    ],
    tinted: [
      "text-action [--btn-bg:theme(colors.action/20%)] [--btn-hover-overlay:theme(colors.white/15%)]",
      "dark:text-action dark:[--btn-bg:theme(colors.action/20%)] dark:[--btn-hover-overlay:theme(colors.white/10%)]",
      "[--icon-color:theme(colors.action)] dark:[--icon-color:theme(colors.action)]",
      "backdrop-blur-lg",
    ],
  },
};

export type ButtonProps = {
  surface?: keyof typeof styles.surfaces;
  mode?: keyof typeof styles.modes;
  circular?: boolean;
  pill?: boolean;
  small?: boolean;
  iconProps?: IconProps;
} & { children?: React.ReactNode } & ButtonOrLinkProps;

type ButtonOrLinkProps = React.ComponentPropsWithoutRef<typeof Link> &
  React.ComponentPropsWithoutRef<typeof HeadlessButton>;

const iconContext: IconProps = {
  // defaults, can be overridden by the icon
  color: "var(--icon-color)",
  weight: "bold",
  size: "1.25rem",
};

export const Button = forwardRef(function Button(
  {
    surface = "system",
    mode = "solid",
    small = false,
    circular = false,
    pill = false,
    children,
    className,
    iconProps = {},
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const classes = clsx(
    className,
    styles.base,
    styles.modes[mode],
    styles.surfaces[surface],
    small && styles.small,
    pill && styles.pill,
    circular && styles.circular
  );

  const mergedIconProps = { ...iconContext, ...iconProps };

  const destinationDefined =
    ("href" in props && props.href != null) ||
    ("to" in props && props.to != null);

  return destinationDefined ? (
    <Link
      {...props}
      className={classes}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
    >
      <IconContext.Provider value={mergedIconProps}>
        <TouchTarget>{children}</TouchTarget>
      </IconContext.Provider>
    </Link>
  ) : (
    <HeadlessButton
      {...(props as HeadlessButtonProps)}
      className={clsx(classes, "cursor-pointer")}
      ref={ref}
    >
      <IconContext.Provider value={mergedIconProps}>
        <TouchTarget>{children}</TouchTarget>
      </IconContext.Provider>
    </HeadlessButton>
  );
});

/* Expand the hit area to at least 44×44px on touch devices */
export function TouchTarget({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
      />
    </>
  );
}
