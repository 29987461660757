import { DataInteractive as HeadlessDataInteractive } from "@headlessui/react";
import {
  Link as RemixLink,
  LinkProps as RemixLinkProps,
} from "@remix-run/react";
import React, { forwardRef } from "react";

export type LinkProps =
  | ({ to?: string; href?: string } & RemixLinkProps)
  | ({
      to?: never;
      href?: string;
    } & React.AnchorHTMLAttributes<HTMLAnchorElement>);

export const Link = forwardRef(function Link(
  { to, href, ...props }: LinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const isExternal =
    !!href || to?.startsWith("http://") || to?.startsWith("https://");

  if (isExternal) {
    return (
      <HeadlessDataInteractive>
        <a ref={ref} href={href ?? to} {...props} />
      </HeadlessDataInteractive>
    );
  } else if (to) {
    return (
      <HeadlessDataInteractive>
        <RemixLink ref={ref} to={to} {...props} />
      </HeadlessDataInteractive>
    );
  } else {
    // Fallback for when both `to` and `href` are undefined
    console.warn("Link component rendered without `to` or `href`", props);
    return null;
  }
});
